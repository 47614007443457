import { VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin((nuxtApp) => {
  const recaptchaConfig = useConfig().recaptcha

  if (recaptchaConfig == undefined) {
    return
  }

  nuxtApp.vueApp.use(
    VueReCaptcha,
    {
      siteKey: recaptchaConfig.siteKey,
      loaderOptions: {
        autoHideBadge: true,
      }
    })
});

